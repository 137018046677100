import { CombinedState } from '..';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { News, NewsView, applySelectionToView, createView } from './entities';
import { getNews } from './api';
import { LoadingState } from 'ui';

export enum ActionType {
    SetNews = 'LoginPageSetNews',
    SetNewsView = 'LoginPageSetNewsView',
    SetCurrentNewsIndex = 'LoginPageSetCurrentNewsId',
    SetLoadingNews = 'LoginPageSetLoadingNews',
    SetIntervalId = 'LoginPageSetIntervalId',
}

interface Action<T extends ActionType, P> {
    type: T;
    payload: P;
}

type SetNews = Action<ActionType.SetNews, News[]>;
type SetNewsView = Action<ActionType.SetNewsView, NewsView[]>;
type SetCurrentNewsIndex = Action<ActionType.SetCurrentNewsIndex, number>;
type SetLoadingNews = Action<ActionType.SetLoadingNews, LoadingState>;
type SetIntervalId = Action<ActionType.SetIntervalId, number>;

export type RootAction = SetNews | SetNewsView | SetCurrentNewsIndex | SetLoadingNews | SetIntervalId;

export const setNews = (news: News[]): SetNews => ({
    type: ActionType.SetNews,
    payload: news,
});

export const setNewsView = (newsView: NewsView[]): SetNewsView => ({
    type: ActionType.SetNewsView,
    payload: newsView,
});

export const selectItemByIndex =
    (index: number): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().news;
        let newCurrentNewsIndex = index;
        let oldCurrentNewsIndex = state.currentNewsIndex;
        if (newCurrentNewsIndex == oldCurrentNewsIndex) {
            return;
        }

        let newsView = state.newsView;
        newsView = applySelectionToView({ newsView, newCurrentNewsIndex, oldCurrentNewsIndex });

        localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());
        dispatch(setCurrentNewsIndex(newCurrentNewsIndex));
        dispatch(setNewsView(newsView));
    };

export const loadNews =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    async (dispatch: ThunkDispatch<CombinedState, unknown, RootAction>): Promise<void> => {
        dispatch(setLoadingNews(LoadingState.InProgress));
        try {
            let news = await getNews();
            let newsView = createView([...news]);
            dispatch(setNews(news));

            let currentNewsIndex = 0;
            let newCurrentNewsIndex = 0;

            if (localStorage.getItem('currentNewsIndex')) {
                currentNewsIndex = Number(localStorage.getItem('currentNewsIndex'));
                newCurrentNewsIndex = currentNewsIndex + 1;
            }

            if (newCurrentNewsIndex > newsView.length - 1) {
                newCurrentNewsIndex = 0;
            }

            localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());

            newsView = applySelectionToView({ newsView, newCurrentNewsIndex, oldCurrentNewsIndex: currentNewsIndex });

            dispatch(setNewsView(newsView));
            dispatch(setCurrentNewsIndex(newCurrentNewsIndex));
            dispatch(setLoadingNews(LoadingState.Success));
        } catch (error) {
            dispatch(setLoadingNews(LoadingState.Failure));
            console.error(error);
        }
    };

export const setCurrentNewsIndex = (index: number): SetCurrentNewsIndex => ({
    type: ActionType.SetCurrentNewsIndex,
    payload: index,
});

export const showNextNewsImage =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().news;
        let newCurrentNewsIndex = state.currentNewsIndex + 1;
        let oldCurrentNewsIndex = state.currentNewsIndex;
        if (newCurrentNewsIndex > state.newsView.length - 1) {
            newCurrentNewsIndex = 0;
        }

        let newsView = state.newsView;
        newsView = applySelectionToView({ newsView, newCurrentNewsIndex, oldCurrentNewsIndex });

        localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());
        dispatch(setCurrentNewsIndex(newCurrentNewsIndex));
        dispatch(setNewsView(newsView));
    };

export const showPrevNewsImage =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().news;
        let newCurrentNewsIndex = state.currentNewsIndex - 1;
        let oldCurrentNewsIndex = state.currentNewsIndex;
        if (newCurrentNewsIndex < 0) {
            newCurrentNewsIndex = state.newsView.length - 1;
        }

        let newsView = state.newsView;
        newsView = applySelectionToView({ newsView, newCurrentNewsIndex, oldCurrentNewsIndex });

        localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());
        dispatch(setCurrentNewsIndex(newCurrentNewsIndex));
        dispatch(setNewsView(newsView));
    };

const setLoadingNews = (status: LoadingState): SetLoadingNews => ({
    type: ActionType.SetLoadingNews,
    payload: status,
});

export const setIntervalId = (intervalId: number): SetIntervalId => ({
    type: ActionType.SetIntervalId,
    payload: intervalId,
});

export const resetTimer =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch, getState: () => CombinedState): void => {
        let state = getState().news;
        clearInterval(state.intervalId);
        let newIntervalId = setInterval(() => {
            showNextNewsImage()(dispatch, getState, {});
        }, 10_000);
        dispatch(setIntervalId(Number(newIntervalId)));
    };
