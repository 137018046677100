import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getLoadingState, LoadingState } from 'ui';
import Loading from '../../components/loading';
import { useLoginDispatch } from '../../store';
import { loadLanguages } from '../../store/language-select/actions';
import { getLanguages, getLoadingLanguages, getTranslates } from '../../store/language-select/selectors';
import { returnLanguage } from '../../store/language-select/entities';
import {
    checkConfigCreated,
    updateOldInterface,
    login,
    updateLogin,
    updatePassword,
    updateLanguage,
} from '../../store/login-page/actions';
import { getAuthorizationData, getConfig, getConfigLoadingRequest } from '../../store/login-page/selectors';
import { loadNews } from '../../store/news/actions';
import { Content } from './content';
import { FormAuthorization } from './menu-authorization/menu-authorization';

const createStyles = makeStyles(() => ({
    container: {
        width: '100%',
        display: 'flex',
        fontFamily: '"Montserrat", sans-serif',
    },
}));

export const LoginPage: React.FC = () => {
    const dispatch = useLoginDispatch();
    let classes = createStyles();
    const authorizationData = useSelector(getAuthorizationData);
    const configLoadingRequest = useSelector(getConfigLoadingRequest);
    const loadingLanguages = useSelector(getLoadingLanguages);
    const [autologinParam, setAutologinParam] = React.useState<boolean>(true);

    let translates = useSelector(getTranslates);
    const languages = useSelector(getLanguages);
    const config = useSelector(getConfig);

    let currentLanguage = languages.find((item) => item.code == authorizationData.language);

    if (currentLanguage) {
        translates = currentLanguage.translates;
    }
    React.useEffect(() => {
        dispatch(checkConfigCreated());
        dispatch(loadLanguages());
    }, []);

    React.useEffect(() => {
        if (config && languages.length !== 0) {
            const params = new URLSearchParams(document.location.search);
            const loginParam = params.get('login');
            const passwordParam = params.get('password');
            const newIntParam = params.get('newint') === 'true' ? true : false;
            const autologinParam = params.get('autologin') === 'true' ? true : false;

            if (loginParam !== null) {
                dispatch(updateLogin(loginParam));
            }
            if (passwordParam !== null) {
                dispatch(updatePassword(passwordParam));
            }

            dispatch(updateOldInterface(newIntParam));

            const localStorageLang = localStorage.getItem('lang')?.toLocaleLowerCase() || '';
            const browserLanguage = window.navigator.language.toLocaleLowerCase();
            const language = returnLanguage(languages, browserLanguage, localStorageLang);
            dispatch(updateLanguage(language));

            if (autologinParam) {
                dispatch(login());
            } else {
                setAutologinParam(autologinParam);
            }
        }
    }, [config, languages]);

    React.useEffect(() => {
        if (!autologinParam) {
            dispatch(loadNews());
        }
    }, [autologinParam]);

    React.useEffect(() => {
        document.title = translates.SoftwareName;
    }, [translates]);

    const loading = getLoadingState(loadingLanguages, configLoadingRequest);

    if (autologinParam || loading === LoadingState.InProgress) {
        return <Loading />;
    }

    return (
        <div className={classes.container}>
            <FormAuthorization />
            <Content />
        </div>
    );
};
