import { LoadingState } from 'ui';
import { ActionType, RootAction } from './actions';
import { NewsState } from './entities';

const initialState: NewsState = {
    news: [],
    newsView: [],
    currentNewsIndex: 0,
    isLoadingNews: LoadingState.InProgress,
    intervalId: 0,
};

export const reducer = (state: NewsState = initialState, action: RootAction): NewsState => {
    switch (action.type) {
        case ActionType.SetNews:
            return { ...state, news: action.payload };
        case ActionType.SetNewsView:
            return { ...state, newsView: action.payload };
        case ActionType.SetCurrentNewsIndex:
            return { ...state, currentNewsIndex: action.payload };
        case ActionType.SetLoadingNews:
            return { ...state, isLoadingNews: action.payload };
        case ActionType.SetIntervalId:
            return { ...state, intervalId: action.payload };
        default:
            return state;
    }
};
