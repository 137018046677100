import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Theme from '../../../theme/login-theme';
import { useLoginDispatch } from '../../store';
import { resetTimer, selectItemByIndex, setIntervalId, showNextNewsImage } from '../../store/news/actions';
import { NewsView } from '../../store/news/entities';

const createStyles = makeStyles((theme: Theme) => ({
    imageItem: theme.custom?.content.imageItem || {},
    selectedImage: theme.custom?.content.selectedImage || {},
}));

interface PreviewsPaneProps {
    newsView: NewsView[];
}

export const PreviewsPane: React.FC<PreviewsPaneProps> = ({ newsView }) => {    
    let classes = createStyles();
    const dispatch = useLoginDispatch();

    const onPreviewClick = (index: number): void => {
        dispatch(selectItemByIndex(index));
        dispatch(resetTimer());
    };

    React.useEffect(() => {
        const localStorageNewsIndex = localStorage.getItem('currentNewsIndex');
        dispatch(selectItemByIndex(Number(localStorageNewsIndex)));
    }, []);

    React.useEffect(() => {
        let intervalId = setInterval(() => {
            dispatch(showNextNewsImage());
        }, 10_000);
        dispatch(setIntervalId(Number(intervalId)));
    }, []);

    return (
        <>
            {newsView.length != 0 &&
                newsView.map((item, index) => {
                    return (
                        <div
                            className={`${item.selected ? classes.selectedImage : classes.imageItem}`}
                            style={{
                                backgroundImage: `url("${item.previewImagePath}")`,
                            }}
                            key={index}
                            onClick={() => onPreviewClick(index)}
                        />
                    );
                })}
        </>
    );
};
