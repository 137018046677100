import { ThunkAction } from 'redux-thunk';
import { EmptyObject } from '../../../utilities';

import { ThemesStateSlice as State, DefaultTheme } from './entities';

export enum ActionType {
    SetCurrentTheme = 'ThemesSetCurrentTheme',
}

interface Action<T extends ActionType, P> {
    type: T;
    payload: P;
}

type SetCurrentTheme = Action<ActionType.SetCurrentTheme, string>;

export type RootAction = SetCurrentTheme;

const createSetCurrentThemeKey = (payload: string): SetCurrentTheme => ({
    type: ActionType.SetCurrentTheme,
    payload,
});

export const changeCurrentTheme =
    (themeKey: string): ThunkAction<void, State, EmptyObject, RootAction> =>
    (dispatch, getState): void => {
        let { themes } = getState().themes;
        let themesKeys = Object.keys(themes);

        let newThemeKey = DefaultTheme;
        if (themesKeys.includes(themeKey)) {
            newThemeKey = themeKey;
        }

        dispatch(createSetCurrentThemeKey(newThemeKey));
    };
