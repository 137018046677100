import { LoadingState } from 'ui';
import { ActionType, RootAction } from './actions';
import { LoginPageState } from './entities';

const getTimezoneOffsetHours = () => (new Date().getTimezoneOffset() / 60) * -1; //умножение на '-1' тк getTimezoneOffset() возвращает отрицательное значение

const initialState: LoginPageState = {
    authorizationData: {
        login: '',
        password: '',
        language: '',
        timezoneOffsetHours: getTimezoneOffsetHours(),
    },
    signinData: {
        objectsCount: 0,
        egrObjectsCount: 0,
        egrUrl: '',
        need2FA: false,
        token2FA: '',
        sessionId: '',
    },
    configCreated: false,
    isLoadingConfig: LoadingState.InProgress,
    oldInterface: false,
    errorMessageText: '',
    loginErrorVisible: false,
    passwordErrorVisible: false,
};

export const reducer = (state: LoginPageState = initialState, action: RootAction): LoginPageState => {
    switch (action.type) {
        case ActionType.SetAuthorizationData:
            return { ...state, authorizationData: action.payload };
        case ActionType.SetConfigCreated:
            return { ...state, configCreated: action.payload };
        case ActionType.SetLoadingConfig:
            return { ...state, isLoadingConfig: action.payload };
        case ActionType.SetOldInterface:
            return { ...state, oldInterface: action.payload };
        case ActionType.SetErrorMessageText:
            return { ...state, errorMessageText: action.payload };
        case ActionType.SetLoginErrorVisible:
            return { ...state, loginErrorVisible: action.payload };
        case ActionType.SetPasswordErrorVisible:
            return { ...state, passwordErrorVisible: action.payload };
        default:
            return state;
    }
};
