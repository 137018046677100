import axios from 'axios';
import { checkAxiosError, getRootUrl } from 'utilities';
import { News } from './entities';

export const getNews = async (): Promise<News[]> => {
    const response = await axios.get(`${getRootUrl()}api/v2/advertisment`);
    
    checkAxiosError(response);

    return response.data;
};
