import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { LoadingState } from 'ui';
import { getRootUrl } from 'utilities';
import { CombinedState } from '..';
import { authorization, getConfig } from './api';
import { AuthorizationData } from './entities';

export enum ActionType {
    SetAuthorizationData = 'LoginPageSetAuthorizationData',
    SetConfigCreated = 'LoginPageSetConfigCreated',
    SetLoadingConfig = 'LoginPageSetLoadingConfig',
    SetOldInterface = 'LoginPageSetOldInterface',
    SetErrorMessageText = 'LoginPageSetErrorMessageText',
    SetLoginErrorVisible = 'LoginPageSetLoginErrorVisible',
    SetPasswordErrorVisible = 'LoginPageSetPasswordErrorVisible',
}

interface Action<T extends ActionType, P> {
    type: T;
    payload: P;
}

type SetAuthorizationData = Action<ActionType.SetAuthorizationData, AuthorizationData>;
type SetConfigCreated = Action<ActionType.SetConfigCreated, boolean>;
type SetLoadingConfig = Action<ActionType.SetLoadingConfig, LoadingState>;
type SetOldInterface = Action<ActionType.SetOldInterface, boolean>;
type SetErrorMessageText = Action<ActionType.SetErrorMessageText, string>;
type SetLoginErrorVisible = Action<ActionType.SetLoginErrorVisible, boolean>;
type SetPasswordErrorVisible = Action<ActionType.SetPasswordErrorVisible, boolean>;

export type RootAction =
    | SetAuthorizationData
    | SetConfigCreated
    | SetOldInterface
    | SetErrorMessageText
    | SetLoginErrorVisible
    | SetPasswordErrorVisible
    | SetLoadingConfig;

export const setAuthorizationData = (authorizationData: AuthorizationData): SetAuthorizationData => ({
    type: ActionType.SetAuthorizationData,
    payload: authorizationData,
});

export const setConfigCreated = (configCreated: boolean): SetConfigCreated => ({
    type: ActionType.SetConfigCreated,
    payload: configCreated,
});

export const updateLogin =
    (login: string): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().loginPage;
        let newAuthorizationData = { ...state.authorizationData, login };

        if (login !== '') {
            dispatch(setLoginErrorNotVisible());
        }

        dispatch(setAuthorizationData(newAuthorizationData));
    };

export const updatePassword =
    (password: string): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().loginPage;
        let newAuthorizationData = { ...state.authorizationData, password };

        if (password !== '') {
            dispatch(SetPasswordErrorNotVisible());
        }

        dispatch(setAuthorizationData(newAuthorizationData));
    };

export const updateLanguage =
    (language: string): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().loginPage;
        localStorage.setItem('lang', language);
        let newAuthorizationData = { ...state.authorizationData, language };

        dispatch(setAuthorizationData(newAuthorizationData));
    };

export const updateOldInterface =
    (oldInterface: boolean): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>): void => {
        dispatch(setOldInterface(oldInterface));
    };

export const login =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    async (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): Promise<void> => {
        let state = getState().loginPage;

        if (state.authorizationData.login == '') {
            dispatch(setLoginErrorVisible());
            return;
        }

        if (state.authorizationData.password == '') {
            dispatch(setPasswordErrorVisible());
            return;
        }

        if (!state.authorizationData) {
            return;
        }

        try {
            let signinData = await authorization(state.authorizationData);

            if (signinData.need2FA) {
                localStorage.setItem('token2FA', signinData.token2FA);
                localStorage.setItem('egrUrl', signinData.egrUrl);
                document.location.href = `/newinterface/mainx/buildmainx/2fa.html`;
                return;
            }

            if (signinData.objectsCount == 0 && signinData.egrObjectsCount > 0) {
                localStorage.setItem('sessionId', signinData.sessionId);
                localStorage.setItem('egrUrl', signinData.egrUrl);
                document.location.href = signinData.egrUrl;
                return;
            }

            let locationUrl = `${getRootUrl()}newinterface/mainx/buildmainx/index.html`;

            if (state.oldInterface) {
                locationUrl = `${getRootUrl()}main.aspx`;
            } else {
                localStorage.setItem('sessionId', signinData.sessionId);
                localStorage.setItem('egrUrl', signinData.egrUrl);
            }

            document.location.href = locationUrl;
        } catch (error) {
            dispatch(setTextErrorMessage((error as Error).message));
        }
    };

export const checkConfigCreated =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    async (dispatch: ThunkDispatch<unknown, unknown, RootAction>): Promise<void> => {
        dispatch(setLoadingConfig(LoadingState.InProgress));
        try {
            const config = await getConfig();
            if (!config) {
                document.location.href = `${getRootUrl()}install/index.html`;
                return;
            }
            dispatch(setConfigCreated(config));
            dispatch(setLoadingConfig(LoadingState.Success));
        } catch (error) {
            dispatch(setLoadingConfig(LoadingState.Failure));
        }
    };

const setLoadingConfig = (status: LoadingState): SetLoadingConfig => ({
    type: ActionType.SetLoadingConfig,
    payload: status,
});

export const setOldInterface = (oldInterface: boolean): SetOldInterface => ({
    type: ActionType.SetOldInterface,
    payload: oldInterface,
});

export const setTextErrorMessage = (errorMessageText: string): SetErrorMessageText => ({
    type: ActionType.SetErrorMessageText,
    payload: errorMessageText,
});

export const setLoginErrorVisible = (): SetLoginErrorVisible => ({
    type: ActionType.SetLoginErrorVisible,
    payload: true,
});

export const setLoginErrorNotVisible = (): SetLoginErrorVisible => ({
    type: ActionType.SetLoginErrorVisible,
    payload: false,
});

export const setPasswordErrorVisible = (): SetPasswordErrorVisible => ({
    type: ActionType.SetPasswordErrorVisible,
    payload: true,
});

export const SetPasswordErrorNotVisible = (): SetPasswordErrorVisible => ({
    type: ActionType.SetPasswordErrorVisible,
    payload: false,
});
