import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { LoadingState } from 'ui';
import { CombinedState } from '../index';
import { getLanguageList } from './api';
import { LanguagesList, Translations } from './entities';

export enum ActionType {
    SetLanguages = 'LanguagesSelectSetLanguages',
    SetLoadingLanguages = 'LanguagesSelectSetLoadingLanguages',
    SetTextErrorMessage = 'LanguagesSelectSetTextErrorMessage',
    SetTranslates = 'LanguagesSelectSetTranslates',
}

interface Action<T extends ActionType, P> {
    type: T;
    payload: P;
}

type SetLanguages = Action<ActionType.SetLanguages, LanguagesList>;
type SetLoadingLanguages = Action<ActionType.SetLoadingLanguages, LoadingState>;
type SetTextErrorMessage = Action<ActionType.SetTextErrorMessage, string>;
type SetTranslates = Action<ActionType.SetTranslates, Translations>;

export type RootAction = SetLanguages | SetLoadingLanguages | SetTextErrorMessage | SetTranslates;

export const loadLanguages =
    (): ThunkAction<void, CombinedState, unknown, RootAction> =>
    async (dispatch: ThunkDispatch<CombinedState, unknown, RootAction>): Promise<void> => {
        dispatch(setLoadingLanguages(LoadingState.InProgress));
        try {
            let apiLanguages = await getLanguageList();
            dispatch(setLanguages(apiLanguages));

            dispatch(setLoadingLanguages(LoadingState.Success));
        } catch (error) {
            dispatch(setLoadingLanguages(LoadingState.Failure));
            dispatch(setTextErrorMessage((error as Error).toString()));
        }
    };

export const updateTranslations =
    (language: string): ThunkAction<void, CombinedState, unknown, RootAction> =>
    (dispatch: ThunkDispatch<unknown, unknown, RootAction>, getState: () => CombinedState): void => {
        let state = getState().languages;
        let currentTranslations = state.languages.find((item) => item.code == language);
        
        if (!currentTranslations) {
            return;
        }

        dispatch(setTranslates(currentTranslations.translates));
    };

export const setLanguages = (languages: LanguagesList): SetLanguages => ({
    type: ActionType.SetLanguages,
    payload: languages,
});

const setLoadingLanguages = (status: LoadingState): SetLoadingLanguages => ({
    type: ActionType.SetLoadingLanguages,
    payload: status,
});

export const setTextErrorMessage = (textErrorMessage: string): SetTextErrorMessage => ({
    type: ActionType.SetTextErrorMessage,
    payload: textErrorMessage,
});

export const setTranslates = (translates: Translations): SetTranslates => ({
    type: ActionType.SetTranslates,
    payload: translates,
});
