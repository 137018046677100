import axios from 'axios';
import { checkAxiosError } from 'utilities';
import { getRootUrl } from 'utilities';
import { LanguagesList } from './entities';

export const getLanguageList = async (): Promise<LanguagesList> => {
    const response = await axios.get<LanguagesList>(`${getRootUrl()}api/v2/languages`);
    checkAxiosError(response);

    return response.data;
};
