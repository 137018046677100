import { LoadingState } from 'ui';
import { CombinedState } from '../index';
import { LanguagesList, Translations } from './entities';

export const getLanguages = (state: CombinedState): LanguagesList => state.languages.languages;

export const getLoadingLanguages = (state: CombinedState): LoadingState => state.languages.isLoadingLanguages;

export const getTextErrorMessage = (state: CombinedState): string => state.languages.textErrorMessage;

export const getTranslates = (state: CombinedState): Translations => state.languages.translates;

export const getCountLanguages = (state: CombinedState): number => state.languages.languages.length;
