import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { LanguagesState } from './language-select/entities';
import { reducer as languagesReducer } from './language-select/reducer';
import { LoginPageState } from './login-page/entities';
import { reducer as loginPageReducer } from './login-page/reducer';
import { NewsState } from './news/entities';
import { reducer as newsReducer } from './news/reducer';
import { ThemesStateSlice } from './theme/entities';
import { reducer as themesReducer } from './theme/reducer';

export interface CombinedState extends ThemesStateSlice {
    languages: LanguagesState;
    loginPage: LoginPageState;
    news: NewsState;
}

export const store = configureStore({
    reducer: combineReducers({
        themes: themesReducer,
        languages: languagesReducer,
        loginPage: loginPageReducer,
        news: newsReducer,
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
type AppDispatch = typeof store.dispatch;
export const useLoginDispatch: () => AppDispatch = useDispatch;
