import { LoadingState } from 'ui';

export interface Language {
    name: string;
    code: string;
    image: string;
    translates: Translations;
    isDefault: boolean;
}

export interface Translations {
    Enter: string;
    EnterLoginData: string;
    ErrorEmptyPassword: string;
    ErrorLoginEmpty: string;
    Login: string;
    MoreDetails: string;
    OldInterface: string;
    Password: string;
    Welcome: string;
    SoftwareName: string;
    Hide: string;
    Show: string;
}

export type LanguagesList = Language[];

export interface LanguagesState {
    languages: LanguagesList;
    isLoadingLanguages: LoadingState;
    textErrorMessage: string;
    translates: Translations;
}

export const defaultTranslates = {
    Enter: 'Войти',
    EnterLoginData: 'Пожалуйста, введите данные для входа',
    ErrorEmptyPassword: 'Поле "Пароль" не может быть пустым',
    ErrorLoginEmpty: 'Поле "Логин" не может быть пустым',
    Login: 'Логин',
    MoreDetails: 'Подробнее',
    OldInterface: 'Старый интерфейс',
    Password: 'Пароль',
    Welcome: 'Добро пожаловать',
    SoftwareName: 'FortMonitor',
    Hide: 'Скрыть',
    Show: 'Показать',
};

export function returnLanguage (languages: LanguagesList, browserLanguage: string, localStorageLang: string): string {
    const availableLanguages = languages.map((item) => item.code);
    const defaultLanguage = languages.find((item) => item.isDefault === true);
    const existLang = languages.some((item) => item.code === localStorageLang);
    let language = '';
    if (!existLang) {
        language = 'ru-ru';
    }

    if (localStorageLang !== '') {
        return localStorageLang;
    }

    if (defaultLanguage) {
        return defaultLanguage.code;
    }

    if (availableLanguages.includes(browserLanguage)) {
        return browserLanguage;
    }

    return language;
};
