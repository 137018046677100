import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Theme from '../../../../theme/login-theme';
import { AppStoreIcon } from '../../../../ui/icons/app-store-icon';
import { GooglePlayIcon } from '../../../../ui/icons/google-play-icon';
import { EnterButton } from '../../../components/button/enter-button';
import { PopUpError } from '../../../components/error/pop-up-error';
import { LanguageSelect } from '../../../components/forms/language-select';
import { LoginField } from '../../../components/forms/login-field';
import { SwitchOldInterface } from '../../../components/forms/login-switch';
import { PasswordField } from '../../../components/forms/password-field';
import { Logo } from '../../../components/logo/logo';
import { useLoginDispatch } from '../../../store';
import { updateTranslations } from '../../../store/language-select/actions';
import {
    getCountLanguages,
    getLanguages,
    getLoadingLanguages,
    getTextErrorMessage as getTextErrorMessageLang,
    getTranslates,
} from '../../../store/language-select/selectors';
import {
    login,
    updateLanguage,
    updateLogin,
    updateOldInterface,
    updatePassword,
} from '../../../store/login-page/actions';
import {
    getAuthorizationData,
    getConfig,
    getConfigLoadingRequest,
    getOldInterface,
    getTextErrorMessage as getTextErrorMessageLogin,
} from '../../../store/login-page/selectors';
import { defaultTranslates } from '../../../store/language-select/entities';
import { LoadingState } from 'ui';
import { toast, translate } from 'utilities';

const createStyles = makeStyles((theme: Theme) => ({
    input: theme.custom?.inputField.input || {},
    typeIconBlock: theme.custom?.inputField.typeIconBlock || {},
    backgroundBlock: theme.custom?.menuAuth.backgroundBlock || {},
    auth: theme.custom?.menuAuth.auth || {},
    appLinks: theme.custom?.menuAuth.appLinks || {},
    logoBlock: theme.custom?.menuAuth.logoBlock || {},
    greeting: theme.custom?.menuAuth.greeting || {},
    greetingHeader: theme.custom?.menuAuth.greetingHeader || {},
    greetingText: theme.custom?.menuAuth.greetingText || {},
    loginBlock: theme.custom?.menuAuth.loginBlock || {},
    passBlock: theme.custom?.menuAuth.passBlock || {},
    languageBlock: theme.custom?.menuAuth.languageBlock || {},
    storeIcon: theme.custom?.storeIcon || {},
}));

export const FormAuthorization: React.FC = () => {
    const classes = createStyles();
    const dispatch = useLoginDispatch();
    const authorizationData = useSelector(getAuthorizationData);
    const oldInterface = useSelector(getOldInterface);
    let translates = useSelector(getTranslates);
    const loadingLanguages = useSelector(getLoadingLanguages);
    const countLanguages = useSelector(getCountLanguages);
    const config = useSelector(getConfig);
    const configLoadingRequest = useSelector(getConfigLoadingRequest);

    let textErrorMessage = '';
    const textErrorMessageLogin = useSelector(getTextErrorMessageLogin);
    if (textErrorMessageLogin) {
        textErrorMessage = textErrorMessageLogin;
    }

    const textErrorMessageLang = useSelector(getTextErrorMessageLang);
    if (textErrorMessageLang) {
        textErrorMessage = textErrorMessageLang;
    }

    if (!config || configLoadingRequest === LoadingState.Failure) {
        toast.info(translate('ConfigNotExist'));
    }

    if (loadingLanguages === LoadingState.Failure) {
        translates = defaultTranslates;
        textErrorMessage = 'Не удалось загрузить языковые пакеты';
    }
    const languages = useSelector(getLanguages);

    let flagIconPath = '';
    let currentLanguage = languages.find((item) => item.code == authorizationData.language);

    if (currentLanguage) {
        flagIconPath = currentLanguage.image;
    }

    const onChangeLogin = React.useCallback((login: string) => {
        dispatch(updateLogin(login));
    }, []);

    const onChangePassword = React.useCallback((password: string): void => {
        dispatch(updatePassword(password));
    }, []);

    const onChangeLanguage = React.useCallback((language: string) => {
        dispatch(updateLanguage(language));
        dispatch(updateTranslations(language));
    }, []);

    const onChangeLoginSwitch = React.useCallback((oldInterface: boolean) => {
        dispatch(updateOldInterface(oldInterface));
    }, []);

    const onLoginButtonClick = React.useCallback(() => {
        dispatch(login());
    }, []);

    const onGooglePlayIconClick = React.useCallback(() => {
        window.open('https://play.google.com/store/apps/details?id=com.fort_telecom.fortmonitor', '_blank');
    }, []);

    const onAppStoreIconClick = React.useCallback(() => {
        window.open('https://apps.apple.com/ru/app/fort-monitor/id1445831699', '_blank');
    }, []);

    React.useEffect(() => {
        dispatch(updateTranslations(authorizationData.language));
    }, []);

    return (
        <div className={classes.backgroundBlock}>
            <div className={classes.logoBlock}>
                <Logo />
                <div className={classes.auth}>
                    <div className={classes.greeting}>
                        <div className={classes.greetingHeader}>{translates.Welcome}!</div>
                        <div className={classes.greetingText}>{translates.EnterLoginData}</div>
                    </div>
                    <div>
                        {textErrorMessage !== '' && <PopUpError textErrorMessage={textErrorMessage} />}
                        <div className={classes.loginBlock}>
                            <LoginField
                                value={authorizationData.login}
                                onChange={onChangeLogin}
                                errorText={translates.ErrorLoginEmpty}
                                placeholder={translates.Login}
                            />
                        </div>

                        <div className={classes.passBlock}>
                            <PasswordField
                                value={authorizationData.password}
                                onChange={onChangePassword}
                                errorText={translates.ErrorEmptyPassword}
                                placeholder={translates.Password}
                                tooltipHideText={translates.Hide}
                                tooltipShowText={translates.Show}
                            />
                        </div>
                        {Boolean(countLanguages) && (
                            <div className={classes.languageBlock}>
                                <LanguageSelect
                                    value={authorizationData.language}
                                    onChange={onChangeLanguage}
                                    flagIconPath={flagIconPath}
                                />
                            </div>
                        )}
                        <SwitchOldInterface
                            checked={oldInterface}
                            onChange={onChangeLoginSwitch}
                            translate={translates.OldInterface}
                        />
                        <EnterButton textKey={translates.Enter} onClick={onLoginButtonClick} />
                    </div>
                </div>
                <div className={classes.appLinks}>
                    <div className={classes.storeIcon} onClick={onAppStoreIconClick}>
                        <AppStoreIcon lang={authorizationData.language} />
                    </div>
                    <div className={classes.storeIcon} onClick={onGooglePlayIconClick}>
                        <GooglePlayIcon lang={authorizationData.language} />
                    </div>
                </div>
            </div>
        </div>
    );
};
