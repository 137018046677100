import { LoadingState } from 'ui';
import { CombinedState } from '..';
import { AuthorizationData } from './entities';

export const getAuthorizationData = (state: CombinedState): AuthorizationData => state.loginPage.authorizationData;

export const getConfig = (state: CombinedState): boolean => state.loginPage.configCreated;

export const getConfigLoadingRequest = (state: CombinedState): LoadingState => state.loginPage.isLoadingConfig;

export const getOldInterface = (state: CombinedState): boolean => state.loginPage.oldInterface;

export const getTextErrorMessage = (state: CombinedState): string => state.loginPage.errorMessageText;

export const getEmptyLoginField = (state: CombinedState): boolean => state.loginPage.loginErrorVisible;

export const getEmptyPasswordField = (state: CombinedState): boolean => state.loginPage.passwordErrorVisible;
