import { LoadingState } from 'ui';
import { CombinedState } from '..';
import { News, NewsView } from './entities';

export const getNews = (state: CombinedState): News[] => state.news.news;

export const getNewsView = (state: CombinedState): NewsView[] => state.news.newsView;

export const getCurrentNewsIndex = (state: CombinedState): number => state.news.currentNewsIndex;

export const getLoadingNews = (state: CombinedState): LoadingState => state.news.isLoadingNews;

export const getIntervalId = (state: CombinedState): number => state.news.intervalId;
