import { LoadingState } from 'ui';
import { ActionType, RootAction } from './actions';
import { defaultTranslates, LanguagesState } from './entities';

const initialState: LanguagesState = {
    languages: [],
    isLoadingLanguages: LoadingState.InProgress,
    textErrorMessage: '',
    translates: defaultTranslates,
};

export const reducer = (state: LanguagesState = initialState, action: RootAction): LanguagesState => {
    switch (action.type) {
        case ActionType.SetLanguages:
            return { ...state, languages: action.payload };
        case ActionType.SetLoadingLanguages:
            return { ...state, isLoadingLanguages: action.payload };
        case ActionType.SetTextErrorMessage:
            return { ...state, textErrorMessage: action.payload };
        case ActionType.SetTranslates:
            return { ...state, translates: action.payload };
        default:
            return state;
    }
};
