import { LoadingState } from "ui";

export interface NewsState {
    news: News[];
    newsView: NewsView[];
    currentNewsIndex: number;
    isLoadingNews: LoadingState;
    intervalId: number;
}

export interface News {
    index: number;
    caption: string;
    previewImagePath: string;
    imageHDPath: string;
    imageFullHDPath: string;
    imageQuadHDPath: string;
    image4KPath: string;
    body: string[];
    link: string;
}

export interface NewsView {
    caption: string;
    previewImagePath: string;
    imageHDPath: string;
    imageFullHDPath: string;
    imageQuadHDPath: string;
    image4KPath: string;
    body: string[];
    link: string;
    selected: boolean;
}

export function createView(news: News[]): NewsView[] {
    let result = news.map((item) => {
        return {
            caption: item.caption,
            previewImagePath: item.previewImagePath,
            imageHDPath: item.imageHDPath,
            imageFullHDPath: item.imageFullHDPath,
            imageQuadHDPath: item.imageQuadHDPath,
            image4KPath: item.image4KPath,
            body: item.body,
            link: item.link,
            selected: false,
        };
    });

    return result;
}

export interface ApplySelectionToViewProps {
    newsView: NewsView[];
    newCurrentNewsIndex: number;
    oldCurrentNewsIndex: number;
}

export function applySelectionToView(props: ApplySelectionToViewProps): NewsView[] {
    let { newsView, newCurrentNewsIndex, oldCurrentNewsIndex } = props;
    let result = [...newsView];

    if (newsView.length != 0) {
        result[oldCurrentNewsIndex].selected = false;
        result[newCurrentNewsIndex].selected = true;
    }

    return result;
}

export function getBackgroundImagePath(windowWidth: number, currentNews: NewsView): string {
    if (windowWidth <= 1366) return currentNews?.imageHDPath;

    if (windowWidth <= 1920) return currentNews?.imageFullHDPath;

    if (windowWidth <= 2560) return currentNews?.imageQuadHDPath;

    return currentNews?.image4KPath;
}
