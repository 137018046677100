import { Theme } from '@material-ui/core/styles';
import { defaultTheme } from '../../../theme/built-in-themes';

export interface ThemesStateSlice {
    themes: ThemesState;
}

export const DefaultTheme = 'default';

export interface ThemesState {
    themes: ThemesType;
    currentTheme: string;
}

export interface ThemesType {
    [theme: string]: LoginTheme;
}

export interface LoginTheme {
    themeName: string;
    value: Theme;
}

export function createDefaultState(): ThemesState {
    return {
        themes: {
            default: {
                themeName: 'Default',
                value: defaultTheme,
            },
        },
        currentTheme: DefaultTheme,
    };
}
