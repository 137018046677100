import { ActionType, RootAction } from './actions';
import { createDefaultState, ThemesState } from './entities';

export function reducer(state: ThemesState = createDefaultState(), action: RootAction): ThemesState {
    switch (action.type) {
        case ActionType.SetCurrentTheme:
            return { ...state, currentTheme: action.payload };
        default:
            return state;
    }
}
