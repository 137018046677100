import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { InputIconSize } from '../../../constants';
import Theme from '../../../theme/login-theme';
import { useLoginDispatch } from '../../store';
import { login, setLoginErrorVisible } from '../../store/login-page/actions';
import { getAuthorizationData, getEmptyLoginField } from '../../store/login-page/selectors';
import { ErrorIcon } from '../icons/errorIcon';
import { SvgIcon } from '../icons/svg-icon';

export const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.inputField.root || {},
    typeIconBlock: theme.custom?.inputField.typeIconBlock || {},
    fullWidth: theme.custom?.inputField.fullWidth || {},
    error: theme.custom?.inputField.error || {},
    input: theme.custom?.inputField.input || {},
    inputField: theme.custom?.inputField.inputField || {},
    additionalInputStyle: theme.custom?.inputField.additionalLoginInputStyle || {},
    dividerBlock: theme.custom?.dividerBlock || {},
}));

interface LoginInputProps {
    value: string;
    fullWidth?: boolean;
    onChange?: (value: string) => void;
    errorText?: string;
    placeholder?: string;
}
export const LoginField: React.FC<LoginInputProps> = (props) => {
    let { value, fullWidth, onChange, errorText, placeholder } = props;
    let classes = createStyles();
    const dispatch = useLoginDispatch();
    const emptyLoginField = useSelector(getEmptyLoginField);
    const loginData = useSelector(getAuthorizationData).login;

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        if (onChange) {
            onChange(event.target.value);
        }
    }, []);

    const onBlurHandler = React.useCallback((): void => {
        if (loginData == '') {
            dispatch(setLoginErrorVisible());
        }
    }, [loginData]);

    const onKeyUp = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key == 'Enter') {
            dispatch(login());
        }
    }, []);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const focusInput = () => {
        inputRef.current?.focus();
    };

    let rootClass = classes.root;

    if (fullWidth) {
        rootClass += ` ${classes.fullWidth}`;
    }

    return (
        <>
            <div className={classes.typeIconBlock}>
                <SvgIcon type="user" size={InputIconSize} />
            </div>
            <div className={classes.dividerBlock} />
            <div className={`${rootClass} ${classes.additionalInputStyle}`} onClick={focusInput}>
                <input
                    ref={inputRef}
                    autoFocus={true}
                    placeholder={placeholder}
                    className={`${classes.input} ${classes.inputField}`}
                    value={value}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    onKeyUp={onKeyUp}
                />
                {emptyLoginField && (
                    <div className={classes.error} title={errorText}>
                        <ErrorIcon />
                    </div>
                )}
            </div>
        </>
    );
};
